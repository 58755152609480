<template>
  <section>
    <v-list two-line v-if="voci.length">
      <template v-for="(voce, index) in voci">
        <v-list-tile
          @click.stop="openVoceDialog(voce)"
          :key="'tile-'+voce._id">
          <v-list-tile-content>
            <v-list-tile-title v-text="voce.descrizione"></v-list-tile-title>
            <v-list-tile-sub-title>{{ voce.prezzo | currency }}</v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-chip small :color="!voce.attivo ? 'grey dark-3' : 'success'" text-color="white">
              <span v-if="voce.attivo">Attiva</span>
              <span v-if="!voce.attivo">Non attiva</span>
            </v-chip>
            <v-icon @click="eliminaVoce = true; voce = voce" color="error">delete</v-icon>
          </v-list-tile-action>
        </v-list-tile>
        <v-divider :key="voce._id" v-if="index < voci.length - 1" />
      </template>
    </v-list>
    <v-alert
      outline=""
      color="info"
      value="true"
      v-if="!voci.length">
      Nessuna voce trovata
    </v-alert>
    <!-- modale voce -->
    <v-dialog
      v-model="showVoce"
      transition="dialog-bottom-transition"
      :overlay="false"
      max-width="900">
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="showVoce=false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formVoce" v-model="validFormVoce" lazy-validation>
            <v-container fluid>
              <v-layout row wrap>
                <v-flex xs12 lg12>
                  <v-text-field
                    label="Descrizione voce*"
                    v-model="voce.descrizione"
                    name="descrizione"
                    :rules="[$rules.required, $rules.maxlength(100)]" />
                  <currency-input
                    v-model.number="voce.prezzo"
                    label="Prezzo"
                    name="prezzo" />
                  <v-select
                    label="Tipologia prezzo"
                    v-model="voce.forfait"
                    :items="prezzi"
                    item-text="descr"
                    item-value="id" />
                  <v-select
                    label="Stato"
                    :items="stati"
                    item-text="nome"
                    item-value="id"
                    v-model="voce.attivo" />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 lg6>
                  <v-btn
                    class="ml-0"
                    color="primary"
                    :disabled="!validFormVoce"
                    id="salvaVoce"
                    @click.native="save">
                    Salva
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- conferma elimina voce -->
    <v-dialog v-model="eliminaVoce" persistent max-width="250" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="headline">Sicuro?</v-card-title>
        <v-card-text>Stai per cancellare la voce <strong>{{ voce.descrizione }}</strong>.</v-card-text>
        <v-card-actions>
          <v-btn @click.native="eliminaVoce = false">Annulla</v-btn>
          <v-btn color="error" @click.native="removeVoce">Elimina</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip left>
      <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click.stop="openVoceDialog()">
        <v-icon color="white">add</v-icon>
      </v-btn>
      <span>Nuova voce fattura</span>
    </v-tooltip>
  </section>
</template>
<script>
import { $EventBus } from '@/eventBus'
import currencyInput from '@/components/common/CurrencyInput'
import _clone from 'lodash/clone'
export default {
  data: () => ({
    cliente: null,
    voci: [],
    voce: {},
    showVoce: false,
    eliminaVoce: false,
    validFormVoce: false,
    stati: [{ id: 1, nome: 'Attiva' }, { id: 0, nome: 'Non attiva' }],
    prezzi: [{ descr: 'Prezzo a copia', id: 0 }, { descr: 'Prezzo a bancale', id: 1 }, { descr: 'Prezzo a forfait', id: 2 }]
  }),
  components: {
    currencyInput
  },
  computed: {
    modalTitle () {
      return this.voce._id ? 'Modifica voce fattura' : 'Nuova voce fattura'
    }
  },
  methods: {
    getVoci () {
      this.$plsqlReadAll('voce', { cliente: this.cliente })
        .then(response => {
          this.voci = response.data
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura voci' })
        })
    },
    save () {
      if (this.$refs.formVoce.validate()) {
        this.$plsqlSave('voce', this.voce)
          .then(response => {
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Voce salvata'
            })
            this.showVoce = false
            this.getVoci()
          })
          .catch(err => {
            console.log(err)
            $EventBus.$emit('message', { type: 'error', text: 'Errore salvataggio voce' })
          })
      }
    },
    removeVoce () {
      this.$plsqlDelete('voce', this.voce._id)
        .then(response => {
          $EventBus.$emit('message', { type: 'success', text: 'Voce eliminata' })
          this.eliminaVoce = false
          this.getVoci()
        })
        .catch(err => {
          if (err.data.errCode === 'ORA-02292') {
            $EventBus.$emit('message', { type: 'error', text: 'Non è possibile cancellare la Voce perché usata' })
          } else {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          }
          this.eliminaVoce = false
        })
    },
    openVoceDialog (voce) {
      this.voce = voce ? _clone(voce) : { cliente_id: this.cliente }
      if (!this.eliminaVoce) {
        this.showVoce = true
        if (!voce) this.$refs.formVoce.reset()
      }
    }
  },
  mounted () {
    this.cliente = this.$route.params.id
    this.getVoci()
  }
}
</script>
