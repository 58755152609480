<template>
  <v-text-field
    v-model="formattedValue"
    :label="label"
    :disabled="disabled"
    :name="name"
    @focus="focus()"
    v-on="inputListeners"
    :rules="rules"
    autocomplete="off"
    @blur="blur()" />
</template>
<script>
import accounting from 'accounting-js'
export default {
  props: {
    value: {
      type: Number
    },
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean
    },
    precision: {
      type: String
    },
    rules: {
      type: Array
    }
  },
  data () {
    return {
      formattedValue: null,
      currencyOptions: {
        symbol: process.env.VUE_APP_CURRENCY_SYMBOL,
        decimal: process.env.VUE_APP_CURRENCY_DECIMAL,
        thousand: process.env.VUE_APP_CURRENCY_THOUSAND,
        precision: parseInt(this.precision || process.env.VUE_APP_CURRENCY_PRECISION)
      }
    }
  },
  methods: {
    format (v) {
      if (v !== null && v !== undefined) {
        return accounting.formatMoney(v, this.currencyOptions)
      }
    },
    parse (v) {
      return accounting.unformat(v, ',')
    },
    focus () {
      if (this.formattedValue) {
        this.formattedValue = this.parse(this.formattedValue)
      }
    },
    blur () {
      const numRe = /[\d]*[.]{0,1}[\d]+/g
      if (this.formattedValue !== null && this.formattedValue !== undefined && (typeof this.formattedValue === 'number' || this.formattedValue.length)) {
        this.formattedValue = '' + this.formattedValue
        this.formattedValue = this.formattedValue.replace(',', '.')
        let parts = this.formattedValue.split('.')
        if (parts.length > 1) {
          this.formattedValue = parts.slice(0, -1).join('') + '.' + parts.slice(-1)
          this.formattedValue = this.formattedValue.match(numRe).join([])
        }
        this.formattedValue = this.format(this.formattedValue)
        this.$emit('input', this.parse(this.formattedValue))
      } else {
        this.$emit('input', null)
      }
    }
  },
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            // if aggiunto da me perché se no dà errore (quando event è undefined)
            if (event && event.target) vm.$emit('input', event.target.value)
          }
        })
    }
  },
  watch: {
    value: function (val) {
      this.formattedValue = this.format(val)
    }
  },
  mounted () {
    this.formattedValue = this.format(this.value)
  }
}
</script>
